<template>
	<form v-if="currentState === 'form'" class="u-login-and-registration__tab-content" @submit.prevent="submit" novalidate="true">
		<span class="text-color-primary font-set-4-bold d-block">Passwort vergessen?</span>
		<span class="font-set-8 d-block">Keine Sorge, passiert den Besten</span>

		<p class="u-form__error-message" v-if="formErrorMessage" role="alert" aria-atomic="true">{{ formErrorMessage }}</p>

		<AtomsInputText
			name="loginUsername"
			label="E-Mail-Adresse"
			v-model="formMail.fieldValue"
			:error-message="formMail.errorMessage"
			:isValidated="formMail.isValidated"
			@change="formMail.validate"
			class="mt-7"
			:required="true"
			:inputAttrs="{
				'aria-describedby': 'password-forgotten-hint',
			}"
		></AtomsInputText>
		<p class="mt-7 mb-0 fs-4" id="password-forgotten-hint">
			Bitte tragen Sie hier Ihre E-Mail-Adresse ein. Wir senden Ihnen anschließend einen Link, über den Sie Ihr Passwort ändern
			können.
		</p>
		<AtomsBaseCTA class="u-login-and-registration__submit-button mt-7 d-block" version="primary" type="submit">
			Passwort zurücksetzen
		</AtomsBaseCTA>
		<AtomsBaseCTA version="link" class="text-color-primary u-login-and-registration__secondary-button" @click="$emit('goto-login')">
			zurück zur Anmeldung
		</AtomsBaseCTA>
	</form>
	<section class="u-login-and-registration__tab-content fs-4" v-else-if="currentState === 'success'">
		<span class="text-color-primary font-set-4-bold d-block">Passwort ändern</span>
		<span class="font-set-8 d-block wb">Es wurde eine E-Mail an {{ formMail.fieldValue }} versendet!</span>
		<img src="/static/images/password-reset-success.svg" class="mx-auto mt-7 d-block max-w-100" />
		<p class="mt-7">
			Wenn ein Konto vorhanden ist, erhalten Sie in den nächsten Minuten eine E-Mail mit einem Link. Über diesen können Sie ein neues
			Passwort vergeben.
		</p>
		<p>
			Sie haben keine E-Mail erhalten oder Ihre E-Mail-Adresse vergessen?
			<AtomsBaseCTA version="link" class="text-color-primary u-login-and-registration__text-link-button" @click="resetForm(true)">
				Neuen Link per Mail anfordern
			</AtomsBaseCTA>
			oder kontaktieren Sie uns:
		</p>
		<a :href="`mail:${supportEmail}`" class="mr-10">
			<AtomsIconMail class="u-login-and-registration__link-icon mr-4"></AtomsIconMail>{{ supportEmail }}
		</a>
		<a :href="supportPhoneUrl"><AtomsIconPhone class="u-login-and-registration__link-icon mr-4"></AtomsIconPhone>{{ supportPhone }}</a>
	</section>
	<section class="u-login-and-registration__tab-content fs-4" v-else-if="currentState === 'fail'">
		<span class="text-color-primary font-set-4-bold d-block">Passwort ändern</span>
		<span class="font-set-8 d-block wb">Es wurde eine E-Mail an {{ formMail.fieldValue }} versendet!</span>
		<img src="/static/images/password-reset-fail.svg" class="mx-auto mt-10 d-block" />
		<AtomsBaseCTA class="u-login-and-registration__submit-button mt-10 d-block" version="primary" type="button" @click="resetForm">
			Passwort erneut zurücksetzen
		</AtomsBaseCTA>
	</section>
</template>

<script lang="ts" setup>
	import Validate from "../server/utils/form-validator";
	import { computed, ref } from "vue";

	const props = withDefaults(
		defineProps<{
			mail?: string;
			resend?: boolean;
			state?: "form" | "success" | "fail" | "newpasswordform";
		}>(),
		{
			state: "form",
			resend: false,
		},
	);

	const requestUrl = useRequestURL();

	const currentState = ref(props.state);
	const isResend = ref(props.resend);
	const resendToken = ref("");

	const sourcePublication = usePublication();

	const generalErrorMailAddresses: { [key: string]: string } = {
		swp: useRuntimeConfig().public.generalErrorEmailSwp,
		moz: useRuntimeConfig().public.generalErrorEmailMoz,
		lr: useRuntimeConfig().public.generalErrorEmailLr,
	};

	const supportEmail = generalErrorMailAddresses[sourcePublication];
	const supportPhone = useRuntimeConfig().public.digitalSupportPhone;
	const supportPhoneUrl = `tel:${supportPhone.replace(/\s/g, "")}`;

	// FORM CONTROLS
	const formMail = useFormValue({
		default: props.mail,
		validate: (field) => Validate.email(field, "Bitte geben Sie Ihre E-Mail-Adresse ein"),
	});

	const formErrorMessage = ref("");

	function resetForm(resend = false) {
		currentState.value = "form";
		isResend.value = resend;
		formMail.reset();
	}

	async function isValid() {
		return await formMail.validate();
	}

	async function submit() {
		if (!(await isValid())) return;

		formErrorMessage.value = "";

		const data = await fetch("/api/user/password/sendtoken", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
			},
			body: JSON.stringify({
				username: formMail.fieldValue,
				isResend: isResend.value,
				token: resendToken.value,
				returnUrl: useRoute().fullPath,
				publication: usePublication(),
				protocol: requestUrl.protocol,
			}),
		}).then((r) => {
			if (r.ok) {
				currentState.value = "success";
				return r.json();
			} else {
				formErrorMessage.value = r.statusText;
			}
			return r;
		});

		if (data?.token) {
			resendToken.value = data.token;
		}
	}
</script>

<style lang="scss">
	@import "../../storybook/src/assets/css/_mixins.scss";

	/**
     * LOGIN
     */

	.u-login-and-registration__tab-content {
		padding: 2rem;

		@include tablet-above {
			padding: 4.5rem;
		}
	}
	.u-login-and-registration__submit-button {
		font-size: 1.125rem;
		font-weight: bold;
		padding: 1rem 1.5rem;
		line-height: 1;
		width: 100%;
	}

	.u-login-and-registration__secondary-button {
		margin: auto;
		display: block;
		margin-top: 0.625rem;
	}

	.u-login-and-registration__text-link-button {
		padding: 0;
		font-size: 1em;
		min-height: auto;
	}

	.u-login__agreement-wrapper {
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		gap: var(--space-4);
	}

	.u-login-and-registration__link-icon {
		width: 1.25rem;
	}
</style>
